import React, { useEffect, useState } from 'react';
import { Alert, Card, CardTitle, Spinner } from 'reactstrap';
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import { Button, Col, Icon, Row } from '../../../../../../../../components/Component';
import TextInput from '../../../../../../../components/inputs/TextInput';
import ModalPreview from '../../../../../modal/modal_preview/ModalPreview';
import { COMPLETE_PROCESSING_DELIVERY_NOTE_API } from '../../../../../../../../api/api_data';
import { fail_complete_processing_delivery_note, loading_complete_processing_delivery_note, reset_complete_processing_delivery_note, success_complete_processing_delivery_note } from '../../../../../../../../reducers/dashboard_reducers/delivery_notes/complete_processing_delivery_note_Slice';
import { COMPLETE_PROCESSING_DELIVERY_NOTE_ACCESS_DENIED_MSG, COMPLETE_PROCESSING_DELIVERY_NOTE_ERR_MSG, COMPLETE_PROCESSING_DELIVERY_NOTE_SUCCESS_MSG } from '../../../../../../../../constants/success_error_messages_consts';
import { useSelector } from 'react-redux';
import { employee_dashboard_actions_helper } from '../../../../../../../../actions/actions_helper';
import { useDispatch } from 'react-redux';
import { messageToast } from '../../../../../../../../utils/Utils';

const initialState = { delivery_note_id: "", first_weight: "", second_weight: "", net_weight: "", customer_receiving_name: "", customer_receiving_primary_mobile: "", customer_receiving_secondary_mobile: "" };

const FinalDetailsStep = ({ weighBridgeFormData, continue_processing_data, setContinueProcessingData, nextStep }) => {

    const [refData, setRefData] = useState(weighBridgeFormData);
    const [formData, setFormData] = useState(continue_processing_data);
    const [stepData, setStepData] = useState(initialState);
    const [openPreview, setOpenPreview] = useState(false);
    const [errorVal, setErrorVal] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setFormData(weighBridgeFormData);
        setRefData(continue_processing_data);
        setStepData({ ...stepData, ["first_weight"]: continue_processing_data?.first_weight, ["delivery_note_id"]: continue_processing_data?.delivery_note_id });
    }, [weighBridgeFormData, continue_processing_data]);

    // Handle Change
    const handleChange = (e) => {
        setStepData({ ...stepData, [e.target.name]: e.target.value });
    };


    const handleOnblur = (e) => {
        if (e.target.name === "second_weight")
        {
            if (e.target.value)
            {
                if (parseFloat(refData?.first_weight) > parseFloat(e.target.value))
                {
                    setStepData({ ...stepData, [e.target.name]: "", ["net_weight"]: "" });
                }
                else
                {
                    setStepData({ ...stepData, [e.target.name]: parseFloat(e.target.value).toFixed(2), ["net_weight"]: parseFloat(e.target.value - refData?.first_weight).toFixed(2) });
                }
            }
            else
            {

                setStepData({ ...stepData, [e.target.name]: "", ["net_weight"]: "" });
            }
        }
        else
        {
            if (e.target.value)
            {
                setStepData({ ...stepData, [e.target.name]: parseFloat(e.target.value).toFixed(2) });
            }
        }
    };

    // Handle Submit
    const handleSubmit = () => {
        dispatch(
            employee_dashboard_actions_helper(
                loading_complete_processing_delivery_note,
                success_complete_processing_delivery_note,
                fail_complete_processing_delivery_note,
                COMPLETE_PROCESSING_DELIVERY_NOTE_ERR_MSG,
                COMPLETE_PROCESSING_DELIVERY_NOTE_SUCCESS_MSG,
                app_consts.POST,
                COMPLETE_PROCESSING_DELIVERY_NOTE_API,
                stepData,
                COMPLETE_PROCESSING_DELIVERY_NOTE_ACCESS_DENIED_MSG
            )
        );
    };

    // Listen to Submit State
    const complete_processing_delivery_note = useSelector((state) => state.complete_processing_delivery_note.complete_processing_delivery_note);

    useEffect(() => {
        if (complete_processing_delivery_note.status !== app_consts.IDLE)
        {
            if (complete_processing_delivery_note.status === app_consts.LOADING)
            {
                setLoading(true);
            } else
            {
                setLoading(false);
                if (complete_processing_delivery_note.status === app_consts.FAIL)
                {
                    setErrorVal(complete_processing_delivery_note.message);
                    messageToast(complete_processing_delivery_note.message, app_consts.FAIL);
                    setOpenPreview(false);
                } else if (complete_processing_delivery_note.status === app_consts.SUCCESS)
                {
                    setContinueProcessingData(complete_processing_delivery_note.data);
                    messageToast(COMPLETE_PROCESSING_DELIVERY_NOTE_SUCCESS_MSG, app_consts.SUCCESS);
                    nextStep();
                }
                dispatch(reset_complete_processing_delivery_note());
            }
        }
    }, [complete_processing_delivery_note]);

    //Preview Details
    const handlePreview = () => {
        setErrorVal("");
        setOpenPreview(true);
    };

    return (
        <>
            <Card className="card-inner">
                <CardTitle tag="h5">Finilizing Delivery Note Details</CardTitle>
                <Col lg="12">
                    <div className="form-group">
                        {weighBridgeFormData.consigner_type_id === app_consts.UAG_CONSIGNER_TYPE ? (
                            <>
                                <div className="form-label-group">
                                    <label className="form-label text-soft">User Account ID :</label>
                                </div>
                                <div className="form-control-wrap">
                                    <label className="form-label text-primary">UAG</label>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="form-label-group">
                                    <label className="form-label text-soft">User Account ID :</label>
                                </div>
                                <div className="form-control-wrap">
                                    <label className="form-label text-primary">{weighBridgeFormData.user_account_label}</label>
                                </div>
                            </>
                        )}
                    </div>
                </Col>
                <hr />
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <Row className="g-4">
                        <div className="form-label-group">
                            <label className="form-label text-soft fs-22px">Weigh Bridge Details</label>
                        </div>
                        <TextInput
                            parentClassName="mt-1"
                            colSize={6}
                            label="Tare Weight (MT)"
                            type="number"
                            id_val="first_weight"
                            value={refData?.first_weight}
                            // handleChange={handleChange}
                            name="first_weight"
                            disabled
                        />
                        <TextInput
                            parentClassName="mt-1"
                            colSize={6}
                            label="Gross Weight (MT)"
                            type="number"
                            id_val="second_weight"
                            value={stepData.second_weight}
                            handleChange={handleChange}
                            name="second_weight"
                            onBlur={handleOnblur}
                        />
                        <TextInput
                            parentClassName="mt-1"
                            colSize={6}
                            label="Net Weight (MT)"
                            type="number"
                            id_val="net_weight"
                            disabled
                            value={stepData.net_weight}
                            handleChange={handleChange}
                            name="net_weight"
                            onBlur={handleOnblur}
                        />
                        <hr />

                        <div className="form-label-group mt-1">
                            <label className="form-label text-soft fs-22px">Customer Receiving Details</label>
                        </div>
                        <TextInput
                            parentClassName="mt-1"
                            colSize={6}
                            label="Customer Name"
                            type="text"
                            id_val="customer_receiving_name"
                            value={stepData.customer_receiving_name}
                            handleChange={handleChange}
                            name="customer_receiving_name"
                        />
                        <TextInput
                            parentClassName="mt-1"
                            colSize={6}
                            label="Customer Primary Mobile Number"
                            type="text"
                            id_val="customer_receiving_primary_mobile"
                            value={stepData.customer_receiving_primary_mobile}
                            handleChange={handleChange}
                            name="customer_receiving_primary_mobile"
                        />
                        <TextInput
                            parentClassName="mt-1"
                            colSize={6}
                            label="Customer Secondary Mobile Number (Optional)"
                            type="text"
                            id_val="customer_receiving_secondary_mobile"
                            value={stepData.customer_receiving_secondary_mobile}
                            handleChange={handleChange}
                            name="customer_receiving_secondary_mobile"
                        />
                        <Col xl="12">
                            <div className="form-group">
                                <div className="form-control-wrap-flex-wrap justify-end items">
                                    <Button
                                        onClick={handlePreview}
                                        disabled={
                                            loading
                                                ? loading
                                                : !(stepData.second_weight && stepData.customer_receiving_name && stepData.customer_receiving_primary_mobile)
                                        }
                                        color="primary"
                                        size="md"
                                    >
                                        {loading ? <Spinner size="sm" color="light" /> : "Preview & Finalize"}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {errorVal && (
                        <div className="mt-4">
                            <Alert color="danger" className="alert-icon">
                                {" "}
                                <Icon name="alert-circle" />
                                {errorVal}{" "}
                            </Alert>
                        </div>
                    )}
                </form>
            </Card>
            {/* Modal For Previewing Before Create */}
            <ModalPreview
                openPreview={openPreview}
                setOpenPreview={setOpenPreview}
                modalTitle={`Finalize Delivery Note Details Preview`}
                modalDescription={`Please Confirm Delivery Note Details and Select 'Finalize and Save' to Fialize Details and Save Delivery Note.`}
                moduleButtonText="Finalize and Save"
                prevType={app_consts.FINAL_DELIVERY_NOTE_DETAILS}
                prevData={stepData}
                buttonFunction={handleSubmit}
            />
        </>
    );
};

export default FinalDetailsStep;