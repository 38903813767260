import React, { useEffect, useState } from 'react';
import Head from '../../../layout/head/Head';
import Content from '../../../layout/content/Content';
import TablePageHeading from '../../dashboard_pages/components/page_heading/TablePageHeading';
import CardTabs from '../../dashboard_pages/components/cards/CardTabs';
import { Block } from '../../../components/Component';
import TableList from '../../dashboard_pages/components/table/TableList';
import * as app_consts from "../../../constants/react_app_consts";
import { client_actions_helper } from '../../../actions/actions_helper';
import { useDispatch } from 'react-redux';
import { fail_retrieve_goods_received_list, loading_retrieve_goods_received_list, reset_retrieve_goods_received_list, success_retrieve_goods_received_list } from '../../../reducers/client_reducers/goods_received/retrieve_goods_received_list_Slice';
import { RETRIEVE_GOODS_RECEIVED_LIST_ACCESS_DENIED_MSG, RETRIEVE_GOODS_RECEIVED_LIST_ERR_MSG, RETRIEVE_GOODS_RECEIVED_LIST_SUCCESS_MSG } from '../../../constants/success_error_messages_consts';
import { RETRIEVE_CLIENTS_GOODS_RECEIVED_LIST_API } from '../../../api/api_data';
import { useSelector } from 'react-redux';
import { messageToast } from '../../../utils/Utils';

const type = app_consts.CLIENT_GOODS_RECEIVED_LIST;

const GoodsReceivedList = ({ account_type }) => {

    const dispatch = useDispatch();

    const [page_data, setPageData] = useState([]);
    const [activeCardTab, setActiveCardTab] = useState("Fully Processed");
    const [dataFetched, setDataFetched] = useState(false);
    const [fully_processed_data, setFullyProcessedData] = useState([]);
    const [partially_processed_data, setPartiallyProcessedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        retrieve_goods_received_list_func();
    }, []);

    const log_cred = useSelector((state) => state.logged_in_cred.logged_in_cred);

    // Card Tabs
    const tabs = [
        {
            tabName: "Fully Processed", icon: "user-circle"
        },
        {
            tabName: "Pending", icon: "user-circle"
        },
    ];

    // Retrieve Goods Received List for Client
    const retrieve_goods_received_list_func = () => {
        setError("");
        dispatch(
            client_actions_helper(
                loading_retrieve_goods_received_list,
                success_retrieve_goods_received_list,
                fail_retrieve_goods_received_list,
                RETRIEVE_GOODS_RECEIVED_LIST_ERR_MSG,
                RETRIEVE_GOODS_RECEIVED_LIST_SUCCESS_MSG,
                app_consts.GET,
                RETRIEVE_CLIENTS_GOODS_RECEIVED_LIST_API,
                account_type,
                null,
                RETRIEVE_GOODS_RECEIVED_LIST_ACCESS_DENIED_MSG
            )
        );
    };

    // Listen to Reducer
    const retrieve_goods_received_list = useSelector((state) => state.retrieve_goods_received_list.retrieve_goods_received_list);
    // 
    useEffect(() => {
        if (retrieve_goods_received_list.status !== app_consts.IDLE)
        {
            if (retrieve_goods_received_list.status === app_consts.LOADING)
            {
                setLoading(true);
            } else
            {
                setLoading(false);
                if (retrieve_goods_received_list.status === app_consts.FAIL)
                {
                    setError(retrieve_goods_received_list.message);
                    messageToast(retrieve_goods_received_list.message, app_consts.FAIL);
                } else if (retrieve_goods_received_list.status === app_consts.SUCCESS)
                {
                    setPageData(retrieve_goods_received_list.data);
                }
                dispatch(reset_retrieve_goods_received_list());
            }
        }
    }, [retrieve_goods_received_list]);

    // Refresh List Function
    const refreshList = () => {
        retrieve_goods_received_list_func();
        setPageData([]);
    };

    // UseEffect for Page Data
    useEffect(() => {
        if (page_data)
        {
            const complete_data = page_data?.filter((complete) => complete.weigh_bridge_ticket_status.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_COMPLETE);

            const uncomplete_data = page_data?.filter((complete) => complete.weigh_bridge_ticket_status.weigh_bridge_ticket_status_id !== app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_COMPLETE);

            setFullyProcessedData(complete_data);
            setPartiallyProcessedData(uncomplete_data);
        }
    }, [page_data]);

    return (
        <>
            <Head title="Goods Received List" />
            <Content>
                <Block className="items-center w-80 m-auto">
                    {/* Table Page Heading */}
                    <TablePageHeading
                        title="Goods Received Tickets List"
                        description={`Total Goods Received Tickets: ${page_data.length}`}
                        refresh
                        refreshFunc={refreshList}
                        title_addition={log_cred?.data?.username}
                        backToText="Home"
                        backToLink="/client"
                    //   csv_export
                    //   add_to_list={
                    //     componentData.create_goods_received_weigh_bridge_ticket ||
                    //     componentData.create_delivery_note_weigh_bridge_ticket
                    //   }
                    //   componentData={componentData}
                    //   page_type={type}
                    //   pageData={page_data}
                    //   openAddList={openAddListModal}
                    />
                    <CardTabs cardTabs={tabs} activeCardTab={activeCardTab} setActiveCardTab={setActiveCardTab} dataFetched={setDataFetched} />
                    {
                        activeCardTab === "Fully Processed" && (
                            <>
                                <TableList
                                    // left_head_select
                                    type={type}
                                    data={fully_processed_data}
                                //   rowOptionsData={componentData}
                                // amendFunc={amend_Account_Status_modal}
                                // setOpendModalDetails={setOpendModalDetails}
                                // setSelectedData={setSelectedUser}
                                />
                            </>
                        )
                    }
                    {
                        activeCardTab === "Pending" && (
                            <>
                                <TableList
                                    // left_head_select
                                    type={type}
                                    data={partially_processed_data}
                                //   rowOptionsData={componentData}
                                // amendFunc={amend_Account_Status_modal}
                                // setOpendModalDetails={setOpendModalDetails}
                                // setSelectedData={setSelectedUser}
                                />
                            </>
                        )
                    }
                </Block>
            </Content>
        </>
    );
};

export default GoodsReceivedList;