import React, { useEffect, useState } from "react";
import { Button, Col, Icon, RSelect } from "../../../../../../components/Component";
import { Alert, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { employee_dashboard_actions_helper } from "../../../../../../actions/actions_helper";
import {
  fail_active_users_list,
  loading_active_users_list,
  reset_active_users_list,
  success_active_users_list,
} from "../../../../../../reducers/dashboard_reducers/user/active_users_list_Slice";
import {
  RETRIEVE_ACTIVE_USERS_ACCESS_DENIED_MSG,
  RETRIEVE_ACTIVE_USERS_ERR_MSG,
  RETRIEVE_ACTIVE_USERS_SUCCESS_MSG,
} from "../../../../../../constants/success_error_messages_consts";
import { RETRIEVE_ACTIVE_COMPLETE_KYC_LIST } from "../../../../../../api/api_data";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as app_consts from "../../../../../../constants/react_app_consts";

const ModalOnboardCompanySearch = ({ isOpen, setIsOpen }) => {
  const [errorVal, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [openListener, setOpenListener] = useState(isOpen);
  const dispatch = useDispatch();
  const [user_list_data, setUserListData] = useState([]);
  const [isSearch, setisSearch] = useState(false);
  const [onSerchText, setOnSearchText] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);

  useEffect(() => {
    setOpenListener(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (openListener)
    {
      retrieveActiveUsersList();
    }
  }, [openListener]);

  // Function to Receive list of Users
  const retrieveActiveUsersList = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_active_users_list,
        success_active_users_list,
        fail_active_users_list,
        RETRIEVE_ACTIVE_USERS_ERR_MSG,
        RETRIEVE_ACTIVE_USERS_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_ACTIVE_COMPLETE_KYC_LIST,
        null,
        RETRIEVE_ACTIVE_USERS_ACCESS_DENIED_MSG,
        app_consts.MAP,
        app_consts.USER_MAP
      )
    );
  };

  // Read Active Users List State
  const active_users_list = useSelector((state) => state.active_users_list.active_users_list);

  useEffect(() => {
    if (active_users_list.status !== app_consts.IDLE)
    {
      if (active_users_list.status === app_consts.LOADING)
      {
        setLoading(true);
      } else
      {
        setLoading(false);
        if (active_users_list.status === app_consts.FAIL)
        {
          setError(active_users_list.message);
        } else if (active_users_list.status === app_consts.SUCCESS)
        {
          setUserListData(active_users_list.data);
        }
        dispatch(reset_active_users_list());
      }
    }
  }, [active_users_list]);

  //function to declare the state change
  const onActionText = (e) => {
    if (e.length > 4)
    {
      setOnSearchText(e);
      setisSearch(true);
    } else
    {
      setOnSearchText("");
      setisSearch(false);
    }
  };

  //Function to Initiate Search
  //Changing state value when searching name
  useEffect(() => {
    if (isSearch)
    {
      const filteredObject = user_list_data.filter((item) => {
        return (
          item.label.toLowerCase().includes(onSerchText.toLowerCase()) ||
          item.value.toLowerCase().includes(onSerchText.toLowerCase())
        );
      });
      setSearchOptions([...filteredObject]);
    } else
    {
      setSearchOptions([]);
      setOnSearchText("");
    }
  }, [onSerchText]);

  // Refresh List Button
  const refreshList = () => {
    retrieveActiveUsersList();
    setError("");
  };

  return (
    <>
      {errorVal && (
        <div className="mb-3 w-100">
          <Alert color="danger" className="alert-icon">
            <Icon name="alert-circle" /> {errorVal}{" "}
          </Alert>
        </div>
      )}
      <form>
        <Col>
          <div className="form-group">
            <label className="form-label mb-1 text-md" htmlFor="username">
              Enter at least 5 characters to initiate search
            </label>
            <p>Search by Name or Phone Number</p>
            <div className="form-control-wrap align-items-center w-100 d-flex flex-row mb-2 justify-between">
              <div className="w-85">
                <RSelect
                  options={searchOptions}
                  className="w-100"
                  placeholder="Search"
                  id="username"
                  onChange={(e) => setSelectedUser(e.value)}
                  onInputChange={(e) => onActionText(e)}
                  isDisabled={loading}
                />
              </div>
              <div className="w-12">
                {
                  // Waiting for List to Load
                  loading ? (
                    <Spinner />
                  ) : (
                    <Link
                      to="#reload"
                      onClick={(e) => {
                        e.preventDefault();
                        refreshList();
                      }}
                    >
                      <Icon name="reload-alt btn-icon fs-22px"></Icon>
                    </Link>
                  )
                }
              </div>
            </div>
          </div>
        </Col>
        <Col xl="12">
          <div className="form-group mt-2">
            <div className="form-control-wrap-flex-wrap justify-center">
              <Link to={`/dashboard/onboarding/company/${selectedUser}`}>
                <Button disabled={!selectedUser} color="primary" size="lg">
                  <span>Select User</span>
                </Button>
              </Link>
            </div>
          </div>
        </Col>
      </form>
    </>
  );
};

export default ModalOnboardCompanySearch;
