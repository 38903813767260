import React, { useEffect, useState } from 'react';
import { Alert, Card, CardTitle, Spinner } from 'reactstrap';
import { Button, Col, Icon } from '../../../../../../../../components/Component';
import ReadOnlyTable from '../../../../../table/ReadOnlyTable';
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import ModalAdd from '../../../../../modal/modal_add/ModalAdd';
import { FINISH_ADDING_LOADING_ORDER_PRODUCTS_API } from '../../../../../../../../api/api_data';
import { fail_finish_adding_loading_order_products, loading_finish_adding_loading_order_products, reset_finish_adding_loading_order_products, success_finish_adding_loading_order_products } from '../../../../../../../../reducers/dashboard_reducers/loading_orders/finish_adding_loading_order_products_Slice';
import { FINISH_ADDING_LOADING_ORDER_PRODUCTS_ACCESS_DENIED_MSG, FINISH_ADDING_LOADING_ORDER_PRODUCTS_ERR_MSG, FINISH_ADDING_LOADING_ORDER_PRODUCTS_SUCCESS_MSG } from '../../../../../../../../constants/success_error_messages_consts';
import { useSelector } from 'react-redux';
import { messageToast } from '../../../../../../../../utils/Utils';
import { useDispatch } from 'react-redux';
import { employee_dashboard_actions_helper } from '../../../../../../../../actions/actions_helper';

const table_type = app_consts.ADD_LOADING_ORDER_PRODUCT_TABLE;

const AddLoadingOrderProducts = ({ wizard_data, setWizardData, nextStep }) => {

  const [openProductModal, setOpenProductMoal] = useState();
  const [product_data, setProductData] = useState([]);
  const [page_data, setpageData] = useState(wizard_data);
  const [total_mt_capacity, setTotalMTCapacity] = useState(0);
  const [errorVal, setErrorVal] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setpageData(wizard_data);
    setProductData(wizard_data?.loading_order_product_info?.products_list);
  }, [wizard_data]);

  console.log(product_data);

  const successAddFunc = (storage_det = null) => {
    setOpenProductMoal(false);
    setProductData([...product_data, storage_det]);
  };

  // Get Sum of all Goods Received Products
  const totalCapacitySum = product_data?.reduce((sum, item) => {
    // Convert total_capacity_in_mt to a number and add to the sum
    return sum + parseFloat(item?.total_mt_capacity);
  }, 0);

  //   Listen to Product Data Changes
  useEffect(() => {
    setTotalMTCapacity(totalCapacitySum);
  }, [product_data]);

  const finish_adding_loading_order_products = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_finish_adding_loading_order_products,
        success_finish_adding_loading_order_products,
        fail_finish_adding_loading_order_products,
        FINISH_ADDING_LOADING_ORDER_PRODUCTS_ERR_MSG,
        FINISH_ADDING_LOADING_ORDER_PRODUCTS_SUCCESS_MSG,
        app_consts.POST,
        FINISH_ADDING_LOADING_ORDER_PRODUCTS_API,
        { loading_order_ref_no: page_data?.loading_order_ref_no },
        FINISH_ADDING_LOADING_ORDER_PRODUCTS_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to Changes
  const finish_add_listener = useSelector((state) => state.finish_adding_loading_order_products.finish_adding_loading_order_products);

  useEffect(() => {
    if (finish_add_listener.status !== app_consts.IDLE)
    {
      if (finish_add_listener.status === app_consts.LOADING)
      {
        setLoading(true);
      } else
      {
        setLoading(false);
        if (finish_add_listener.status === app_consts.FAIL)
        {
          messageToast(finish_add_listener.message, app_consts.FAIL);
          setErrorVal(finish_add_listener.message);
        } else if (finish_add_listener.status === app_consts.SUCCESS)
        {
          if (finish_add_listener.data)
          {
            setWizardData(finish_add_listener.data);
            nextStep();
          }
        }
        dispatch(reset_finish_adding_loading_order_products());
      }
    }
  }, [finish_add_listener]);

  return (
    <>
      <Card className="card-inner">
        <CardTitle tag="h5">Select Loading Order Products</CardTitle>
        <Col lg="12">
          <div className="form-group d-flex justify-between">
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label text-soft">User Account :</label>
              </div>
              <div className="form-control-wrap">
                <label className="form-label text-primary">{page_data?.owner ? `${page_data?.owner} - ${page_data?.unique_identification_number}` : "--"}</label>
              </div>
            </div>
            <div>
              <div className="form-label-group">
                <label className="form-label text-soft">Total Capacity In MT :</label>
              </div>
              <div className="form-control-wrap">
                <label className="form-label text-primary">{total_mt_capacity ? parseFloat(total_mt_capacity)?.toFixed(2) : "--"}</label>
              </div>
            </div>
          </div>
        </Col>
        <Col xl="12" className="mb-3 mt-3">
          <div className="form-group">
            <div className="form-control-wrap-flex-wrap justify-start">
              <Button onClick={() => setOpenProductMoal(true)} className="btn btn-dim btn-outline-primary" size="md">
                Add Product
              </Button>
            </div>
          </div>
        </Col>
        {/* Table */}
        <div className="my-3">
          <ReadOnlyTable data={product_data} tableType={table_type} />
        </div>
        {errorVal && (
          <div className="mt-4">
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" />
              {errorVal}{" "}
            </Alert>
          </div>
        )}
        <Col xl="12">
          <div className="form-group mt-2">
            <div className="form-control-wrap-flex-wrap justify-end items-center">
              <Button
                onClick={finish_adding_loading_order_products}
                disabled={
                  loading
                    ? loading
                    : !(product_data?.length > 0)
                }
                color="primary"
                size="md"
              >
                {loading ? <Spinner size="sm" color="light" /> : "Finish Adding Products "}
              </Button>
            </div>
          </div>
        </Col>
        <hr />
      </Card>
      {/* Add Modal */}
      <ModalAdd
        openModal={openProductModal}
        setOpenModal={setOpenProductMoal}
        modalTitle="Add Loading Order Product"
        modalDescription="Please Complete this form to Add a Product to Loading Order"
        type={table_type}
        succFunc={successAddFunc}
        accountId={wizard_data}
      />
    </>
  );
};

export default AddLoadingOrderProducts;