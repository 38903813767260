import * as app_consts from "../../../../../constants/react_app_consts";

export const user_list_column = (type) => {
  let column = [];
  switch (type)
  {
    case app_consts.USER_LIST_TYPE:
      column = [
        { key: 0, label: "Username" },
        { key: 1, label: "Phone Number" },
        { key: 2, label: "Email Address" },
        { key: 3, label: "Account Status" },
      ];
      break;
    case app_consts.BRANCH_LOCATION_LIST:
      column = [
        { key: 0, label: "Branch Area" },
        { key: 1, label: "Branch Physical Address" },
        { key: 2, label: "Branch Mobile Number" },
        { key: 3, label: "Province Name" },
        { key: 4, label: "Branch Status" },
      ];
      break;
    case app_consts.PRODUCT_LIST:
      column = [
        { key: 0, label: "Product Name" },
        { key: 1, label: "Product Type" },
        { key: 2, label: "UoM" },
        { key: 3, label: "Product Status" },
      ];
      break;
    case app_consts.PRODUCT_TYPE_LIST:
      column = [
        { key: 0, label: "Product Type Name" },
        { key: 1, label: "No. of Products" },
        { key: 2, label: "Product Type Status" },
      ];
      break;
    case app_consts.UNIT_MEASUREMENT_LIST:
      column = [
        { key: 0, label: "Unit Measurement Name" },
        { key: 1, label: "Unit Measurement Abbrev" },
        { key: 2, label: "Unit Measurement Status" },
      ];
      break;
    case app_consts.EMPLOYEE_LIST_TYPE:
      column = [
        { key: 0, label: "Account ID" },
        { key: 1, label: "Employee Name" },
        { key: 2, label: "Role" },
        { key: 3, label: "Department" },
        { key: 4, label: "Branch" },
        { key: 5, label: "Status" },
      ];
      break;
    case app_consts.GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_LIST:
      column = [
        { key: 0, label: "Goods Received Ref No." },
        { key: 1, label: "Consigner" },
        { key: 2, label: "Consigner ID" },
        { key: 3, label: "Ticket Type" },
        { key: 4, label: "Truck" },
        { key: 5, label: "Status" },
      ];
      break;
    case app_consts.WAREHOUSE_INVENTORY_LIST:
      column = [
        { key: 0, label: "Product Name" },
        { key: 1, label: "Product Type" },
        { key: 2, label: "Virtual Capacity (MT)" },
        { key: 3, label: "Physical Capacity (MT)" },
        { key: 3, label: "Total Capacity (MT)" },
        { key: 4, label: "Product Status" }
      ];
      break;
    case app_consts.DELIVERY_NOTES_WEIGH_BRIDGE_TICKET_LIST:
      column = [
        { key: 0, label: "Delivery Note Ref No." },
        { key: 1, label: "Consigner" },
        { key: 2, label: "Consigner ID" },
        { key: 3, label: "Ticket Type" },
        { key: 4, label: "Property Property" },
        { key: 5, label: "Status" },
      ];
      break;
    case app_consts.CLIENT_GOODS_RECEIVED_LIST:
      column = [
        { key: 0, label: "Goods Received Ref No." },
        { key: 1, label: "Product Name" },
        { key: 2, label: "Weigh Bridge Ticket No." },
        { key: 3, label: "Truck License Plate" },
        { key: 4, label: "Date Created" },
        { key: 5, label: "Status" },
        { key: 6, label: "" },
      ];
      break;
    case app_consts.CUSTOMER_USER_ACCOUNT_LIST:
      column = [
        { key: 0, label: "User Account ID" },
        { key: 1, label: "First Name" },
        { key: 2, label: "Last Name." },
        { key: 3, label: "Account Type" },
        { key: 4, label: "Date Created" },
        { key: 5, label: "Status" },
        { key: 6, label: "" },
      ];
      break;
    case app_consts.CLIENT_DELIVERY_NOTES_LIST:
      column = [
        { key: 0, label: "Delivery Note Ref No." },
        { key: 1, label: "Destination" },
        { key: 2, label: "Weigh Bridge Ticket No." },
        { key: 3, label: "Truck License Plate" },
        { key: 4, label: "Date Created" },
        { key: 5, label: "Status" },
        { key: 6, label: "" },
      ];
      break;
    case app_consts.COMPANY_USER_ACCOUNT_LIST:
      column = [
        { key: 0, label: "User Account ID" },
        { key: 1, label: "Name" },
        { key: 2, label: "Email" },
        { key: 3, label: "Mobile Number" },
        { key: 4, label: "Account Type" },
        { key: 5, label: "Date Created" },
        { key: 6, label: "Status" },
        { key: 7, label: "" },
      ];
      break;
    case app_consts.TRUCK_DRIVER_USER_ACCOUNT_LIST:
      column = [
        { key: 0, label: "User Account ID" },
        { key: 1, label: "First Name" },
        { key: 2, label: "Last Name." },
        { key: 3, label: "Account Type" },
        { key: 4, label: "Date Created" },
        { key: 5, label: "Status" },
        { key: 6, label: "" },
      ];
      break;
    case app_consts.DEPARTMENT_AND_ROLES_LIST:
      column = [
        { key: 0, label: "Role" },
        { key: 1, label: "Department" },
        { key: 2, label: "No. of Employees" },
        { key: 3, label: "No of Actions" },
      ];
      break;
    case app_consts.LOADING_ORDERS_LIST:
      column = [
        { key: 0, label: "Ref No." },
        { key: 1, label: "Owner" },
        { key: 2, label: "Contact Person." },
        { key: 3, label: "Contact Mobile" },
        { key: 4, label: "No. of Products" },
        { key: 5, label: "Status" },
      ];
      break;
  }
  return column;
};

export const read_Only_Columns = (type) => {
  let column = [];
  switch (type)
  {
    case app_consts.ONBOARD_USER_PROPERTY:
      column = [
        { key: 0, label: "Property Name" },
        { key: 1, label: "Province" }, ,
        { key: 2, label: "District" },
      ];
      break;
    case app_consts.ONBOARD_COMPANY_REPRESENTATIVE:
      column = [
        { key: 0, label: "Fullname" },
        { key: 1, label: "Phone Number" },
        { key: 2, label: "Role" },
      ];
      break;
    case app_consts.SPECIFIC_BRANCH_LOCATION_STORAGES:
      column = [
        { key: 0, label: "Storage Reference Number" },
        { key: 1, label: "Storage Capacity" },
        { key: 2, label: "Storage Type" },
        { key: 3, label: "Storage Status" },
      ];
      break;
    case app_consts.SPECIFIC_BRANCH_EMPLOYEES:
      column = [
        { key: 0, label: "Account ID" },
        { key: 1, label: "Employee Name" },
        { key: 2, label: "Role" },
        { key: 3, label: "Department" },
        { key: 4, label: "Status" },
      ];
      break;
    case app_consts.ADD_PRODUCTS_TO_DELIVERY_NOTE_TICKET:
      column = [
        { key: 0, label: "Product" },
        { key: 1, label: "Packaging Total Number" },
        { key: 2, label: "Unit Measurement" },
        { key: 3, label: "Total Capacity" },
        { key: 4, label: "Total Capacity (MT)" },
        { key: 5, label: "" },
      ];
      break;
    case app_consts.USER_ACCOUNT_LIST:
      column = [
        { key: 0, label: "User Account ID" },
        { key: 1, label: "Account Type" },
        { key: 2, label: "Date Created" },
        { key: 3, label: "Account Status" },
        { key: 4, label: "" },
      ];
      break;
    case app_consts.DELIVERY_NOTE_PRODUCT_LIST:
      column = [
        { key: 0, label: "Product" },
        { key: 1, label: "Packaging Total No." },
        { key: 2, label: "Unit Measurement" },
        { key: 3, label: "Total Capacity" },
        { key: 4, label: "Total Capacity (MT)" },
      ];
      break;
    case app_consts.WAREHOUSE_INVENTORY_BRANCHES_LIST:
      column = [
        { key: 0, label: "Branch Area" },
        { key: 1, label: "Product Capacity (MT)" },
        { key: 2, label: "Branch Status" },
        { key: 3, label: "" },
      ];
      break;
    case app_consts.INVENTORY_HISTORY_LIST:
      column = [
        { key: 0, label: "" },
        { key: 1, label: "Date" },
        { key: 2, label: "Message" },
        { key: 3, label: "Warehouse Storage Ref Number" },
        { key: 4, label: "Warehouse Storage Type" },
        { key: 5, label: "Capacity" },
      ];
      break;
    case app_consts.ROLE_EMPLOYEES_LIST:
      column = [
        { key: 0, label: "Account ID" },
        { key: 1, label: "Employee Name" },
        { key: 2, label: "Status" },
      ];
      break;
    case app_consts.GOODS_RECEIVED_PRODUCTS_LIST:
      column = [
        { key: 0, label: "Product Name" },
        { key: 1, label: "UoM" },
        { key: 2, label: "Packaging Total No." },
        { key: 3, label: "Total Capacity" },
        { key: 4, label: "Total Capacity (MT)" },
      ];
      break;
    case app_consts.SPECIFIC_GOODS_RECEIVED_DETAILS:
      column = [
        { key: 0, label: "Product Name" },
        { key: 1, label: "UoM" },
        { key: 2, label: "Packaging Total No." },
        { key: 3, label: "Total Capacity" },
        { key: 4, label: "Total Capacity (MT)" },
      ];
      break;
    case app_consts.DRIVER_INSTRUCTIONS_TABLE_EDIT:
      column = [
        { key: 0, label: "No." },
        { key: 1, label: "Instruction" },
        { key: 2, label: "Edit." },
        { key: 3, label: "Delete" },
      ];
      break;
    case app_consts.ADD_LOADING_ORDER_PRODUCT_TABLE:
      column = [
        { key: 0, label: "Product" },
        { key: 1, label: "Delivery Location" },
        { key: 2, label: "Branch Location" },
        { key: 3, label: "Packaging No." },
        { key: 4, label: "Uom" },
        { key: 5, label: "Total Capacity" },
        { key: 6, label: "Total MT Capacity" },
        { key: 7, label: "Status" },
      ];
      break;
    case app_consts.DRIVER_INSTRUCTIONS_TABLE:
      column = [
        { key: 0, label: "No." },
        { key: 1, label: "Instruction" },
      ];
      break;
    case app_consts.NOT_LOADED_DN_EDIT_MAP:
      column = [
        { key: 0, label: "Product" },
        { key: 1, label: "Packaging No." },
        { key: 2, label: "Uom" },
        { key: 3, label: "Total Capacity" },
        { key: 4, label: "Total MT Capacity" },
        { key: 5, label: "Action" },
      ];
      break;
  };
  return column;
};

// Check List Table Columns
export const check_list_Columns = (type) => {
  let column = [];
  switch (type)
  {
    case app_consts.CUSTOMER_FEEDBACK_TABLE:
      column = [
        { key: 0, label: "Product" },
        { key: 1, label: "Number of Packaging" },
        { key: 2, label: "Greater Than" },
        { key: 3, label: "Equal To" },
        { key: 4, label: "Less Than" },
      ];
      break;
  };
  return column;
};