import React, { useEffect, useState } from 'react';
import retrieve_warehouse_storages_list_Slice from '../../../../../../../../reducers/dashboard_reducers/warehouse/retrieve_warehouse_storages_list_Slice';
import { useDispatch } from 'react-redux';
import { Alert, Card, CardTitle, Spinner } from 'reactstrap';
import { Button, Col, Icon } from '../../../../../../../../components/Component';
import ReadOnlyTable from '../../../../../table/ReadOnlyTable';
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import ModalAdd from '../../../../../modal/modal_add/ModalAdd';
import { employee_dashboard_actions_helper } from '../../../../../../../../actions/actions_helper';
import { fail_complete_adding_goods_received_products, loading_complete_adding_goods_received_products, reset_complete_adding_goods_received_products, success_complete_adding_goods_received_products } from '../../../../../../../../reducers/dashboard_reducers/goods_received/complete_adding_goods_received_products_Slice';
import { COMPLETE_ADDING_GOODS_RECEIVED_NOTE_PRODUCTS_API } from '../../../../../../../../api/api_data';
import { COMPLETE_ADDING_GOODS_RECEIVED_NOTE_PRODUCTS_ACCESS_DENIED_MSG, COMPLETE_ADDING_GOODS_RECEIVED_NOTE_PRODUCTS_ERR_MSG, COMPLETE_ADDING_GOODS_RECEIVED_NOTE_PRODUCTS_SUCCESS_MSG } from '../../../../../../../../constants/success_error_messages_consts';
import { messageToast } from '../../../../../../../../utils/Utils';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

const type = app_consts.ADD_PRODUCTS_TO_GOODS_RECEIVED_NOTE_TICKET;
const goods_received_product_type = app_consts.GOODS_RECEIVED_PRODUCTS_LIST;


const ProductsReceivedStep = ({
    weighBridgeFormData,
    setWeighBridgeFormData,
    continue_processing_data,
    setContinueProcessingData,
    nextStep
}) => {

    const { goods_received_ref_number } = useParams();

    const [errorVal, setErrorVal] = useState("");
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(weighBridgeFormData);
    const [stepData, setStepData] = useState([]);
    const dispatch = useDispatch();
    const [refData, setRefData] = useState([]);
    const [openPreview, setOpenPreview] = useState(false);
    const [product_data, setproductData] = useState([]);
    const [page_data, setPageData] = useState(continue_processing_data);
    const [openAddProductsModal, setOpenAddProductsModel] = useState(false);
    const [total_mt_capacity, setTotalMTCapacity] = useState(0);

    // Add product Modal
    const openPropertyModal = () => {
        setOpenAddProductsModel(true);
    };

    useEffect(() => {
        setFormData(weighBridgeFormData);
        setRefData(continue_processing_data);
        if (continue_processing_data) 
        {
            setStepData({ ...stepData, ["weigh_bridge_ticket_number"]: continue_processing_data?.weigh_bridge_ticket_number });
            if (continue_processing_data?.goods_received_products)
            {
                setproductData(continue_processing_data?.goods_received_products);
            }
        }
    }, [weighBridgeFormData, continue_processing_data]);

    const successAddFunc = (added_data) => {
        setproductData([...product_data, added_data]);
        setOpenAddProductsModel(false);
    };

    const finish_adding_products = () => {
        dispatch(
            employee_dashboard_actions_helper(
                loading_complete_adding_goods_received_products,
                success_complete_adding_goods_received_products,
                fail_complete_adding_goods_received_products,
                COMPLETE_ADDING_GOODS_RECEIVED_NOTE_PRODUCTS_ERR_MSG,
                COMPLETE_ADDING_GOODS_RECEIVED_NOTE_PRODUCTS_SUCCESS_MSG,
                app_consts.POST,
                COMPLETE_ADDING_GOODS_RECEIVED_NOTE_PRODUCTS_API,
                { goods_received_id: refData?.goods_received_id },
                COMPLETE_ADDING_GOODS_RECEIVED_NOTE_PRODUCTS_ACCESS_DENIED_MSG
            )
        );
    };

    // Listen to Submit Changes
    // Listen to inish Adding Products Reducer
    const complete_adding_grn_products = useSelector((state) => state.complete_adding_goods_received_products.complete_adding_goods_received_products);

    useEffect(() => {
        if (complete_adding_grn_products.status !== app_consts.IDLE)
        {
            if (complete_adding_grn_products.status === app_consts.LOADING)
            {
                setLoading(true);
            } else
            {
                setLoading(false);
                if (complete_adding_grn_products.status === app_consts.FAIL)
                {
                    setErrorVal(complete_adding_grn_products.message);
                    messageToast(complete_adding_grn_products.message, app_consts.FAIL);
                } else if (complete_adding_grn_products.status === app_consts.SUCCESS)
                {
                    messageToast(COMPLETE_ADDING_GOODS_RECEIVED_NOTE_PRODUCTS_SUCCESS_MSG, app_consts.SUCCESS);
                    setWeighBridgeFormData({ ...complete_adding_grn_products.data?.goods_reeived_data, ["user_account_label"]: complete_adding_grn_products.data?.user_account });
                    nextStep();
                }
                dispatch(reset_complete_adding_goods_received_products());
            }
        }
    }, [complete_adding_grn_products]);

    // Get Sum of all Goods Received Products
    const totalCapacitySum = product_data?.reduce((sum, item) => {
        // Convert total_capacity_in_mt to a number and add to the sum
        return sum + parseFloat(item?.total_capacity_in_mt);
    }, 0);

    //   Listen to Product Data Changes
    useEffect(() => {
        setTotalMTCapacity(totalCapacitySum);
    }, [product_data]);


    return (
        <>
            <Card className="card-inner">
                <CardTitle tag="h5">Products Received Details</CardTitle>
                <Col lg="12">
                    <div className="form-group d-flex justify-between">
                        <div>
                            {weighBridgeFormData.consigner_type_id === app_consts.UAG_CONSIGNER_TYPE ? (
                                <>
                                    <div className="form-label-group">
                                        <label className="form-label text-soft">User Account ID :</label>
                                    </div>
                                    <div className="form-control-wrap">
                                        <label className="form-label text-primary">UAG</label>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="form-label-group">
                                        <label className="form-label text-soft">User Account ID :</label>
                                    </div>
                                    <div className="form-control-wrap">
                                        <label className="form-label text-primary">{weighBridgeFormData.user_account_label}</label>
                                    </div>
                                </>
                            )}
                        </div>
                        <div>
                            <div className="form-label-group">
                                <label className="form-label text-soft">Total Capacity In MT :</label>
                            </div>
                            <div className="form-control-wrap">
                                <label className="form-label text-primary">{total_mt_capacity ? parseFloat(total_mt_capacity)?.toFixed(2) : "--"}</label>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xl="12" className="mb-3 mt-3">
                    <div className="form-group">
                        <div className="form-control-wrap-flex-wrap justify-start">
                            <Button onClick={openPropertyModal} className="btn btn-dim btn-outline-primary" size="md">
                                Add Product
                            </Button>
                        </div>
                    </div>
                </Col>
                {/* Table */}
                <div className="my-3">
                    <ReadOnlyTable data={product_data} tableType={goods_received_product_type} />
                </div>
                <hr />
                {errorVal && (
                    <div className="mt-4">
                        <Alert color="danger" className="alert-icon">
                            {" "}
                            <Icon name="alert-circle" />
                            {errorVal}{" "}
                        </Alert>
                    </div>
                )}
                <Col xl="12">
                    <div className="form-group mt-2">
                        <div className="form-control-wrap-flex-wrap justify-end items-center">
                            <Button
                                onClick={finish_adding_products}
                                disabled={
                                    loading
                                        ? loading
                                        : !(product_data?.length > 0)
                                }
                                color="primary"
                                size="md"
                            >
                                {loading ? <Spinner size="sm" color="light" /> : "Finish Adding Products "}
                            </Button>
                        </div>
                    </div>
                </Col>
                <hr />
            </Card>
            <ModalAdd
                openModal={openAddProductsModal}
                setOpenModal={setOpenAddProductsModel}
                modalTitle="Add Goods Received Note Products"
                modalDescription="Please Complete this form to Add Product to DGoods Received Note Ticket"
                type={type}
                succFunc={successAddFunc}
                accountId={{ continue_processing_data }}
            />
        </>
    );
};

export default ProductsReceivedStep;