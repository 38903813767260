// ------------------------------------- CLIENT APIS -----------------------------------

//  Goods Received URLS 

// Retrieve Client Goods Received List
export const RETRIEVE_CLIENTS_GOODS_RECEIVED_LIST_API = "goods_received/retrieve_specific_client_goods_received_list";

// Retrieve Specific Client Goods Received
export const RETRIEVE_SPECIFIC_CLIENT_GOODS_RECEIVED_API = "goods_received/retrieve_specific_client_goods_received";

//  Delivery Notes URLS 

// Retrieve Client Goods Received List
export const RETRIEVE_CLIENTS_DELIVERY_NOTES_LIST_API = "delivery_notes/retrieve_specific_client_delivery_notes_list";
// Retrieve Specific Client Delivery Note 
export const RETRIEVE_SPECIFIC_CLIENT_DELIVERY_NOTE_API = "delivery_notes/retrieve_specific_client_delivery_note";
// Confirm Delivery 
export const CONFIRM_DELIVERY_NOTE_DELIVERED_API = "delivery_notes/client_confirm_delivery_note_delivered";
// Submit Feedback
export const SUBMIT_CUSTOMER_FEEDBACK_API = "delivery_notes/client_submit_delivery_note_feedback";


// ------------------------------------- UNAUTH APIS --------------------------------

// Username Verification
export const USERNAME_VERIFY_API = "auth/username_verify";
// Send OTP
export const SEND_OTP_AUTH_API = "auth/send_otp_option";
// Verify OTP
export const VERIFY_OTP_AUTH_API = "auth/verify_session_otp";


// ------------------------------------- EMPLOYEE DASHBOARD APIS --------------------------------
// Module Employee Role Actions URLS 

// Get Employee Role Actions
export const GET_EMP_ROLE_ACTIONS_API = "emp_role_action/retrieve_active_specific_employee_actions";

// Users URLS

// Get User List
export const RETRIEVE_USER_LIST_IMG_API = "users/retrieve_user_list_img";
// Create User
export const CREATE_USER_API = "auth/access_account_registration";
// Amend User Status
export const AMEND_USER_STATUS_API = "users/amend_user_account_status";
// Retrieve Active User
export const RETRIEVE_ACTIVE_USERS = "users/retrieve_active_users_list";
// Retrieve User KYC Status
export const RETRIEVE_USER_KYC_STATUS = "user_kyc/retrieve_user_kyc_reg_status";
// Retrieve User KYC Status
export const RETRIEVE_USER_ACTIVE_STATUS_KYC_STATUS = "user_kyc/retrieve_user_active_status_kyc_reg_status";
// Retrieve Specific User Details
export const RETRIEVE_SPECIFIC_USER_DETAILS_API = "users/retrieve_user_details_from_phone_number";

// Admin URLS 
// Retrieve Employee List
export const RETRIEVE_EMPLOYEE_LIST_API = "admin/retrieve_employee_list";
// Retrieve Branch Employee List
export const RETRIEVE_BRANCH_EMPLOYEE_LIST_API = "admin/retrieve_employee_list_for_specific_branch";
// Retrieve Employee Departments and Roles List
export const RETRIEVE_EMPLOYEE_DEPARTMENT_AND_ROLES_LIST_API = "admin/retrieve_departments_and_roles_list";
// Retrive Employee Role Actions
export const RETRIEVE_EMPLOYEE_ROLE_ACTIONS_API = "admin/retrieve_specific_role_employees_actions";


// User Accounts URLS 

// Retrieve Active User
export const RETRIEVE_SPECIFIC_USER_ACC_UASSIGNED = "user_accounts/retrieve_user_accs_list_not_assigned";
// Retrieve Active Assigned User Account List
export const RETRIEVE_ACTIVE_USER_ACCOUNT_LIST = "user_accounts/retrieve_act_truck_cust_comp_accs";
// Create Truck Driver Account
export const CREATE_TRUCK_DRIVER_ACCOUNT = "user_accounts/create_truck_driver_account";
// Create Customer Account
export const CREATE_CUSTOMER_ACCOUNT = "user_accounts/create_customer_account";
// Create Company Account
export const CREATE_COMPANY_ACCOUNT = "user_accounts/create_company_account";
// Retrieve User Accounts List Except Employees
export const RETRIEVE_USER_ACCOUNTS_LIST_EXCEPT_EMPLOYEES_API = "user_accounts/retrieve_user_account_list_except_employees";
// Retrieve Specific User Accounts List Details
export const RETRIEVE_SPECIFIC_USER_ACCOUNT_DETAILS_API = "user_accounts/retrieve_user_account_details";

// Gender URL

// Retrieve Gender Types List
export const RETRIEVE_GENDER_TYPE_LIST = "gender/retrieve_gender_types";

// Marital Status URLS 

// Retrieve Gender Types List
export const RETRIEVE_MARITAL_STATUS_LIST = "marital_status/retrieve_marital_statuses";

// User Title URLS

// Retrieve Gender Types List
export const RETRIEVE_USER_TITLE_LIST = "user_title/retrieve_user_title";

// User KYC URLS

// Update Basic KYC Info
export const UPDATE_BASIC_KYC_INFO = "user_kyc/update_user_kyc_basic_info";
// Update Document KYC Info
export const UPDATE_DOCUMENT_KYC_INFO = "user_kyc/update_user_kyc_document_info";
// Update Address KYC Info
export const UPDATE_ADDRESS_KYC_INFO = "user_kyc/update_user_kyc_address_info";
// UPdate NOK KYC Info
export const UPDATE_NOK_KYC_INFO = "user_kyc/update_user_kyc_nok_info";
// Update Image KYC Info
export const UPDATE_IMAGE_KYC_INFO = "user_kyc/update_user_kyc_image_info";
// Specific KYC Info FOR KYC Completion
export const SPECIFIC_USER_KYC_COMP_KYC_REG = "user_kyc/retrieve_complete_kyc_reg_user_det";
// Uncomplete Active User KYC List
export const UNCOMPLETE_ACTIVE_USER_KYC_LIST = "user_kyc/retrieve_uncomplete_kyc_list";
// Uncomplete Active User KYC List
export const RETRIEVE_ACTIVE_COMPLETE_KYC_LIST = "user_kyc/retreive_active_complete_kyc_list";

// Branch Location URLS

// Retrieve Branch Location List
export const RETRIEVE_BRANCH_LOCATION_LIST = "branch_locations/retrieve_branch_locations_list";
// Add Branch Location
export const ADD_BRANCH_LOCATION = "branch_locations/add_branch_location";
// Amend Branch Location Status 
export const AMEND_BRANCH_LOCATION_STATUS = "branch_locations/amend_branch_location_status";
// Amend Branch Location Status 
export const RETRIEVE_SPECIFIC_BRANCH_LOCATION_API = "branch_locations/retrieve_specific_branch_location";
// Retrieve Active Employee Branch Locations List 
export const RETRIEVE_ACTIVE_EMPLOYEE_BRANCH_LOCATION_LIST_API = "branch_locations/retreieve_all_active_employee_branch_locations";

// Location URLS 

// Retrieve Zambian Provinces List
export const RETRIEVE_ZAMBIAN_PROVINCES_LIST = "locations/retrieve_zambian_provinces";

// Product URLS

// Retrieve Products List
export const RETRIEVE_PRODUCTS_LIST = "products/retrieve_products_list";
// Amend Products Status
export const AMEND_PRODUCTS_STATUS = "products/amend_product_status";
// Amend Products Status
export const ADD_PRODUCT = "products/add_product";
// Retrieve Active Products 
export const RETRIEVE_ACTIVE_PRODUCT_LIST = "products/retrieve_active_products_list";
// Retrieve Active Products 
export const RETRIEVE_ACTIVE_AVAILABLE_PRODUCTS_API = "products/retreive_available_active_products_for_loading_order";



// Product Types URLS

// Retrieve Product Types List
export const RETRIEVE_PRODUCT_TYPES_LIST = "product_types/retrieve_product_types_list";
// Retrieve Product Types List retrieve_active_product_type_list
export const RETRIEVE_ACTIVE_PRODUCT_TYPES_LIST = "product_types/retrieve_active_product_type_list";
// Amend Product Type Status
export const AMEND_PRODUCT_TYPES_STATUS = "product_types/amend_product_type_status";
//Add Product Type
export const ADD_PRODUCT_TYPE = "product_types/add_product_type";

// Unit Measurement URLS

// Retrieve Unit Measurements List
export const RETRIEVE_UNIT_MEASUREMENT_LIST = "unit_measurements/retrieve_unit_measurement_list";
// Amend Unit Measurement Status
export const AMEND_UNIT_MEASUREMENT_STATUS = "unit_measurements/amend_unit_measurement_status";
// Amend Unit Measurement Status 
export const ADD_UNIT_MEASUREMENT = "unit_measurements/add_unit_measurement";
// Retrieve Unit Measurements By Status
export const RETRIEVE_UNIT_MEASUREMENTS_BY_STATUS_ENDPOINT = "unit_measurements/retrieve_unit_measurements_by_status";

// User Account Property URLS

// Add User Account Property
export const ADD_USER_ACCOUNT_PROPERTY = "user_account_property/add_user_account_property";
// Retrieve User Account Property List for Specific User Account
export const RETRIEVE_USER_ACCOUNT_PROPERTIES_BY_UNIQUE_ID_API = "user_account_property/retrieve_specific_user_account_property_by_unique_id";

// Company Representative URLS

// Add Company Representative Role
export const ADD_COMPANY_REPRESENTATIVE_ROLE = "company_representative/add_company_representative_role";
// Retrieve Company Representative Roles
export const RETRIEVE_COMPANY_REPRESENTATIVE_ROLES =
 "company_representative/retrieve_company_representative_roles_list";
// Retrieve Company Representative Roles
export const ADD_COMPANY_REPRESENTATIVE = "company_representative/add_company_representative";

// Trucks and Trailers URLS

// Retrieve Trucks and Trailers List
export const RETRIEVE_TRUCKS_TRAILERS_LIST = "trucks/retreive_truck_trailers_list";

// Weigh Bridge Ticket Types URLS

// Retrieve Weigh Bridge Ticket Type List
export const RETRIEVE_WEIGH_BRIDGE_TICKET_TYPE_LIST = "weigh_bridge_ticket/retrieve_weigh_bridge_tickets_type";
// Create Weigh Bridge Ticket
export const CREATE_GOODS_RECEIVED_TICKET_API = "weigh_bridge_ticket/create_goods_received_ticket";
// Retrieve Goods Recieved Ticket List retrieve_weigh_bridge_ticket_list
export const RETRIEVE_GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_LIST_API = "weigh_bridge_ticket/retrieve_goods_received_weigh_bridge_ticket_list";
// Book Goods Receieved Into Inventory
export const BOOK_GOODS_RECEIVED_INTO_INVENTORY_API = "weigh_bridge_ticket/book_goods_received_into_inventory";

// Warehouse URLS

// Create Warehouse Storage Type 
export const CREATE_WAREHUOSE_STORAGE_TYPE_API = "warehouse/create_warehouse_storage_type";
// Retrieve Warehouse Storage Types 
export const RETRIEVE_WAREHOUSE_STORAGE_TYPES_API = "warehouse/retrieve_warehouse_storage_types";
// Create Warehouse Storage 
export const CREATE_WAREHOUSE_STORAGE_API = "warehouse/create_warehouse_storage";
// Retrieve Warehouse Storages for Specific Branch Location By Status 
export const RETRIEVE_SPECIFC_BRANCH_STORAGE_LIST_BY_STATUS_ID_API = "warehouse/retrieve_warehouse_storages_for_specific_branch_location_by_status_id";
// Retrieve Warehouse Inventory List
export const RETRIEVE_WAREHOUSE_INVENTORY_LIST_API = "warehouse/retrieve_warehouse_inventory_list";
// Retrieve Active Products In Active Branch Locations List
export const RETRIEVE_ACTIVE_PRODUCT_IN_SPECIFIC_ACTIVE_BRANCHES = "warehouse/retrieve_active_product_list_in_active_inventory_branch_location";
// Retrieve Warehouse Inventory Product Details 
export const RETRIEVE_WAREHOUSE_INVENTORY_PRODUCT_DETAILS_API = "warehouse/retrieve_warehouse_inventory_product_details";

// Goods Received URLS

// Retrieve Specific Goods Received
export const RETRIEVE_SPECIFIC_GOODS_RECEIVED_API = "goods_received/retrieve_specific_goods_received";
// Continue Processing Goods Received
export const CONTINUE_PROCESSING_GOODS_RECEIVED_API = "goods_received/continue_processing_goods_received";
// Create Goods Received Note Product
export const CREATE_GOODS_RECEIVED_NOTE_PRODUCT_API = "goods_received/add_goods_received_products";
// Complete Adding Goods Received Note Products API 
export const COMPLETE_ADDING_GOODS_RECEIVED_NOTE_PRODUCTS_API = "goods_received/complete_adding_goods_received_products";

//  Delivery Notes URLS

// Retrieve Specific Goods Received 
export const CREATE_DELIVERY_NOTE_API = "delivery_note/create_physical_delivery_note";
// Retrieve Delivery Note Ticket List 
export const RETRIEVE_DELIVERY_NOTE_TICKET_LIST_API = "delivery_note/retrieve_delivery_note_ticket_list";
// Create Delivery Note Product
export const CREATE_PHYSICAL_DELIVERY_NOTE_PRODUCT_API = "delivery_note/create_physical_delivery_note_product";
// Complete Adding Delivery Note Products
export const COMPLETE_ADDING_DELIVERY_NOTE_PRODUCTS_API = "delivery_note/complete_adding_delivery_note_products";
// Complete Processing Delivery Note 
export const COMPLETE_PROCESSING_DELIVERY_NOTE_API = "delivery_note/complete_processing_delivery_note";
// Retrieve Specific Delivery Note
export const RETRIEVE_SPECIFIC_DELIVERY_NOTE_API = "delivery_note/retrieve_specific_delivery_note";
// Amend Delivery Note Status
export const AMEND_DELIVERY_NOTE_STATUS_API = "delivery_note/amend_delivery_note_status";
// Continue Processing Delivery Note 
export const CONTINUE_PROCESSING_DELIVERY_NOTE_API = "delivery_note/continue_processing_delivery_note";

// Loading Order URLS 

export const CREATE_LOADING_ORDER_API = "loading_order/create_loading_order";
export const RETRIEVE_LOADING_ORDERS_LIST_API = "loading_order/retrieve_loading_order_list";
export const ADD_PRODUCT_TO_LOADING_ORDER_API = "loading_order/add_product_to_loading_order";
export const FINISH_ADDING_LOADING_ORDER_PRODUCTS_API = "loading_order/finish_adding_loading_order_products";
export const RETRIEVE_LOADING_ORDER_DETAILS_API = "loading_order/retrieve_loading_order_details";
export const RETRIEVE_LOADING_ORDERS_LIST_BY_STATUS_API = "loading_order/retrieve_loading_order_list_by_status";
export const RETREIVE_SPECIFIC_DELIVERY_LOCATION_LOADING_ORDER_API = "loading_order/retrieve_loading_order_for_specific_delivery_location";
export const CONTINUE_PROCESSING_LOADING_ORDER_API = "loading_order/continue_processing_loading_order";
export const RETRIEVE_INITAL_DELIVERY_NOTE_LOADING_ORDERS = "loading_order/retrieve_initital_delivery_note_loading_orders";


// Locations
export const RETRIEVE_SPECIFIC_COUNTRY_ALL_LOCATION_API = "locations/retrieve_specific_country_all_user_kyc_det";