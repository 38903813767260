import React, { useEffect, useState } from 'react';
import Head from '../../../layout/head/Head';
import TablePageHeading from '../components/page_heading/TablePageHeading';
import Content from '../../../layout/content/Content';
import CardTabs from '../components/cards/CardTabs';
import TableList from '../components/table/TableList';
import { Block } from '../../../components/Component';
import * as app_consts from "../../../constants/react_app_consts";
import { useSelector } from 'react-redux';
import { fail_retrieve_delivery_notes_ticket_list, loading_retrieve_delivery_notes_ticket_list, reset_retrieve_delivery_notes_ticket_list, success_retrieve_delivery_notes_ticket_list } from '../../../reducers/dashboard_reducers/delivery_notes/retrieve_delivery_notes_ticket_list_Slice';
import { RETRIEVE_DELIVERY_NOTES_TICKETS_ACCESS_DENIED_MSG, RETRIEVE_DELIVERY_NOTES_TICKETS_ERR_MSG, RETRIEVE_DELIVERY_NOTES_TICKETS_SUCCESS_MSG } from '../../../constants/success_error_messages_consts';
import { RETRIEVE_DELIVERY_NOTE_TICKET_LIST_API } from '../../../api/api_data';
import { employee_dashboard_actions_helper } from '../../../actions/actions_helper';
import { messageToast } from '../../../utils/Utils';
import { useDispatch } from 'react-redux';
import { findModuleAction } from '../../../utils/functionalUtils';
import { BiErrorCircle } from 'react-icons/bi';
import { Spinner } from 'reactstrap';

const type = app_consts.DELIVERY_NOTES_WEIGH_BRIDGE_TICKET_LIST;

const initial_actions_data = {
    create_goods_received_weigh_bridge_ticket: false,
    create_delivery_note_weigh_bridge_ticket: false,
};

const DeliveryNotesList = () => {

    const dispatch = useDispatch();

    const [page_data, setPageData] = useState([]);
    const [activeCardTab, setActiveCardTab] = useState("Processing");
    const [dataFetched, setDataFetched] = useState(false);
    const [fully_processed_data, setFullyProcessedData] = useState([]);
    const [partially_processed_data, setPartiallyProcessedData] = useState([]);
    const [componentData, setComponentData] = useState(initial_actions_data);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [in_transite_data, setInTransitData] = useState([]);
    const [delivered_data, setDeliveredData] = useState([]);
    const [account_actions, setAccountActions] = useState([]);

    // UseEffect that Runs When the Page Opens
    useEffect(() => {
        retrieve_delivery_notes_ticket_list_func();
        if (localStorage.getItem("log_cred"))
        {
            setAccountActions(JSON.parse(localStorage.getItem('log_cred'))?.user_actions);
        }
    }, []);

    // Listen to Changes In Account Actions
    useEffect(() => {
        if (account_actions)
        {
            let actions_data = initial_actions_data;
            account_actions?.map((module) => {
                if (module.module_name === app_consts.WAREHOUSE_MANAGEMENT) 
                {
                    // Actions Data
                    if (findModuleAction(app_consts.CREATEd_DELIVERY_NOTE_WEIGH_BRIDGE_TICKET, module))
                    {
                        actions_data.create_delivery_note_weigh_bridge_ticket = true;
                    }
                }
            });
            setComponentData(actions_data);
        }
    }, [account_actions]);

    // Retrieve Delivery Notes Tickets
    const retrieve_delivery_notes_ticket_list_func = () => {
        dispatch(
            employee_dashboard_actions_helper(
                loading_retrieve_delivery_notes_ticket_list,
                success_retrieve_delivery_notes_ticket_list,
                fail_retrieve_delivery_notes_ticket_list,
                RETRIEVE_DELIVERY_NOTES_TICKETS_ERR_MSG,
                RETRIEVE_DELIVERY_NOTES_TICKETS_SUCCESS_MSG,
                app_consts.GET,
                RETRIEVE_DELIVERY_NOTE_TICKET_LIST_API,
                null,
                RETRIEVE_DELIVERY_NOTES_TICKETS_ACCESS_DENIED_MSG
            )
        );
    };


    // Refresh List Function
    const refreshList = () => {
        setError("");
        // Retrieve Weigh Bridge Tickets list
        //   retrieve_user_list_func();
        retrieve_delivery_notes_ticket_list_func();
        setPageData([]);
    };

    // Listen to Delivery Note Ticket List Reducer
    const retrieve_delivery_notes_ticket_list = useSelector((state) => state.retrieve_delivery_notes_ticket_list.retrieve_delivery_notes_ticket_list);
    // 
    useEffect(() => {
        if (retrieve_delivery_notes_ticket_list.status !== app_consts.IDLE)
        {
            if (retrieve_delivery_notes_ticket_list.status === app_consts.LOADING)
            {
                setLoading(true);
            } else
            {
                if (retrieve_delivery_notes_ticket_list.status === app_consts.FAIL)
                {
                    setError(retrieve_delivery_notes_ticket_list.message);
                    messageToast(RETRIEVE_DELIVERY_NOTES_TICKETS_ERR_MSG, app_consts.FAIL);
                } else if (retrieve_delivery_notes_ticket_list.status === app_consts.SUCCESS)
                {
                    setPageData(retrieve_delivery_notes_ticket_list.data);
                }
                setLoading(false);
                dispatch(reset_retrieve_delivery_notes_ticket_list());
            }
        }
    }, [retrieve_delivery_notes_ticket_list]);

    // Card Tabs
    const tabs = [
        {
            tabName: "Processing", icon: "user-circle"
        },
        // {
        //     tabName: "Awaiting Transit", icon: "user-circle"
        // },
        {
            tabName: "In Transit", icon: "user-circle"
        },
        {
            tabName: "Delivered", icon: "user-circle"
        },
    ];

    // UseEffect to Filter Page Data
    useEffect(() => {
        if (page_data)
        {
            const complete_data = page_data?.filter((complete) => complete.weigh_bridge_ticket_status.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_COMPLETE);

            const uncomplete_data = page_data?.filter((complete) =>
                complete.weigh_bridge_ticket_status.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_ONBOARDING_GOODS || complete.weigh_bridge_ticket_status.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_OUTBOUND
            );

            const transit_data = page_data?.filter((complete) => complete.weigh_bridge_ticket_status.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_IN_TRANSIT);

            const deliveredData = page_data?.filter((complete) => complete.weigh_bridge_ticket_status.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_DELIVERED);

            setFullyProcessedData(complete_data);
            setPartiallyProcessedData(uncomplete_data);
            setInTransitData(transit_data);
            setDeliveredData(deliveredData);
        }
    }, [page_data]);

    return (
        <>
            <Head title="Delivery Note Ticket List" />
            <Content>
                {/* Table Page Heading */}
                <TablePageHeading
                    title="Delivery Note Ticket List"
                    description={`Total Delivery Notes Tickets: ${page_data.length}`}
                    refresh={!loading}
                    refreshFunc={refreshList}
                    csv_export={!loading && !error}
                    add_to_list={loading || error ? "" : componentData.create_delivery_note_weigh_bridge_ticket}
                    componentData={componentData}
                    page_type={type}
                    pageData={page_data}
                //   openAddList={openAddListModal}
                />
                {
                    loading ?
                        <>
                            <div className='p-5 d-flex align-center justify-center'>
                                <Spinner />
                            </div>
                        </>
                        :
                        <>
                            {
                                error && error.length > 0 ?
                                    <>
                                        <div className="mt-3 p-5 text-soft alert-icon text-center fw-bold fs-15px">
                                            <BiErrorCircle className="text-danger fs-3" />
                                            <p>{error}</p>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <CardTabs cardTabs={tabs} activeCardTab={activeCardTab} setActiveCardTab={setActiveCardTab} dataFetched={setDataFetched} />
                                        {/* Table */}
                                        <Block>
                                            {
                                                activeCardTab === "Processing" && (
                                                    <>
                                                        <TableList
                                                            // left_head_select
                                                            type={type}
                                                            data={partially_processed_data}
                                                            rowOptionsData={componentData}
                                                        // amendFunc={amend_Account_Status_modal}
                                                        // setOpendModalDetails={setOpendModalDetails}
                                                        // setSelectedData={setSelectedUser}
                                                        />
                                                    </>
                                                )
                                            }
                                            {/* {
                                  activeCardTab === "Awaiting Transit" && (
                                      <>
                                          <TableList
                                              // left_head_select
                                              type={type}
                                              data={fully_processed_data}
                                              rowOptionsData={componentData}
                                          // amendFunc={amend_Account_Status_modal}
                                          // setOpendModalDetails={setOpendModalDetails}
                                          // setSelectedData={setSelectedUser}
                                          />
                                      </>
                                  )
                              } */}
                                            {
                                                activeCardTab === "In Transit" && (
                                                    <>
                                                        <TableList
                                                            // left_head_select
                                                            type={type}
                                                            data={in_transite_data}
                                                            rowOptionsData={componentData}
                                                        // amendFunc={amend_Account_Status_modal}
                                                        // setOpendModalDetails={setOpendModalDetails}
                                                        // setSelectedData={setSelectedUser}
                                                        />
                                                    </>
                                                )
                                            }
                                            {
                                                activeCardTab === "Delivered" && (
                                                    <>
                                                        <TableList
                                                            // left_head_select
                                                            type={type}
                                                            data={delivered_data}
                                                            rowOptionsData={componentData}
                                                        // amendFunc={amend_Account_Status_modal}
                                                        // setOpendModalDetails={setOpendModalDetails}
                                                        // setSelectedData={setSelectedUser}
                                                        />
                                                    </>
                                                )
                                            }
                                        </Block>
                                    </>
                            }
                        </>
                }
            </Content>
        </>
    );
};

export default DeliveryNotesList;