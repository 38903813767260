import React, { useEffect, useState } from 'react';
import { Card, Spinner } from 'reactstrap';
import { Col, Row } from '../../../../../../components/Component';
import StepGuideCard from '../../wizard_steps_guide/StepGuide';
import { useDispatch } from 'react-redux';
import SelectCustomerStep from './steps/create_physical_delivery_note_ticket_steps/SelectCustomerStep';
import { useNavigate, useParams } from 'react-router';
import * as app_consts from "../../../../../../constants/react_app_consts";
import { employee_dashboard_actions_helper } from '../../../../../../actions/actions_helper';
import { fail_user_accounts_list, loading_user_accounts_list, reset_user_accounts_list, success_user_accounts_list } from '../../../../../../reducers/dashboard_reducers/user_accounts/user_accounts_list_Slice';
import { CONTINUE_PROCESSING_DELIVERY_NOTE_ACCESS_DENIED_MSG, CONTINUE_PROCESSING_DELIVERY_NOTE_ERR_MSG, CONTINUE_PROCESSING_DELIVERY_NOTE_SUCCESS_MSG, RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG, RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_ERR_MSG, RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_SUCCESS_MSG } from '../../../../../../constants/success_error_messages_consts';
import { CONTINUE_PROCESSING_DELIVERY_NOTE_API, RETRIEVE_ACTIVE_USER_ACCOUNT_LIST } from '../../../../../../api/api_data';
import { useSelector } from 'react-redux';
import { messageToast } from '../../../../../../utils/Utils';
import TruckDetailsStep from './steps/create_physical_delivery_note_ticket_steps/TruckDetailsStep';
import ProductDetailsStep from './steps/create_physical_delivery_note_ticket_steps/ProductDetailsStep';
import FinalDetailsStep from './steps/create_physical_delivery_note_ticket_steps/FinalDetailsStep';
import { fail_continue_processing_delivery_note, loading_continue_processing_delivery_note, reset_continue_processing_delivery_note, success_continue_processing_delivery_note } from '../../../../../../reducers/dashboard_reducers/delivery_notes/continue_processing_delivery_note_Slice';
import SelectLoadingOrdersStep from './steps/create_physical_delivery_note_ticket_steps/SelectLoadingOrdersStep';
import AddProductsStep from './steps/create_physical_delivery_note_ticket_steps/AddProductsStep';
import DeliveryNotePDFStep from './steps/create_physical_delivery_note_ticket_steps/DeliveryNotePDFStep';

const intial_weigh_bridge_form_data = { consigner_type_id: "", user_account_id: "", user_account_label: "", weigh_bridge_ticket_type_id: "2", ticket_type: "Physical", property_ref_code: "", property_name: "", property_physical_address: "", truck_driver_id: "", truck_id: "", truck_trailer_id_one: "", first_weight: "", truck_driver: "", truck_license_plate: "", trailer_1: "", trailer_2: "", };

const CreatePhsyicalDeliveryNoteIndex = ({ wizardType }) => {

    const { delivery_note_ref_number } = useParams();
    const [step, setStep] = useState(1);
    const [wizardData, setWizardData] = useState([]);
    const [uniqueID, setUniqueID] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userListLoading, setUserListLoading] = useState(false);

    const [truck_accounts, setTruckAccounts] = useState([]);
    const [company_accounts, setCompanyAccounts] = useState([]);
    const [customer_accounts, setCustomerAccounts] = useState([]);

    const [weighBridgeFormData, setWeighBridgeFormData] = useState(intial_weigh_bridge_form_data);
    const [continue_processing_data, setContinueProcessingData] = useState([]);
    const [user_list_data, setUserListData] = useState([]);
    const [loading, setLoading] = useState();
    const [error, setError] = useState();

    // UseEffect on Page Load
    useEffect(() => {
        fetchActiveTruckCustCompAccs();
        if (delivery_note_ref_number)
        {
            setLoading(true);
            // Fetch Data to Continue Processing from
            dispatch(
                employee_dashboard_actions_helper(
                    loading_continue_processing_delivery_note,
                    success_continue_processing_delivery_note,
                    fail_continue_processing_delivery_note,
                    CONTINUE_PROCESSING_DELIVERY_NOTE_ERR_MSG,
                    CONTINUE_PROCESSING_DELIVERY_NOTE_SUCCESS_MSG,
                    app_consts.POST,
                    CONTINUE_PROCESSING_DELIVERY_NOTE_API,
                    { delivery_note_ref_number: delivery_note_ref_number },
                    CONTINUE_PROCESSING_DELIVERY_NOTE_ACCESS_DENIED_MSG
                )
            );
        }
    }, []);

    // Listen to Reducer
    const continue_processing_delivery_note = useSelector((state) => state.continue_processing_delivery_note.continue_processing_delivery_note);

    useEffect(() => {
        if (continue_processing_delivery_note.status !== app_consts.IDLE)
        {
            if (continue_processing_delivery_note.status === app_consts.LOADING)
            {
                setLoading(true);
            } else
            {
                setLoading(false);
                if (continue_processing_delivery_note.status === app_consts.FAIL)
                {
                    setError(continue_processing_delivery_note.message);
                    messageToast(continue_processing_delivery_note.message, app_consts.FAIL);
                } else if (continue_processing_delivery_note.status === app_consts.SUCCESS)
                {
                    setWeighBridgeFormData({ ...weighBridgeFormData, ["user_account_label"]: continue_processing_delivery_note.data?.user_account });
                    setStep(continue_processing_delivery_note.data?.step);
                    setContinueProcessingData(continue_processing_delivery_note.data?.delivery_note_data);
                }
                dispatch(reset_continue_processing_delivery_note());
            }
        }
    }, [continue_processing_delivery_note]);

    const fetchActiveTruckCustCompAccs = () => {
        dispatch(
            employee_dashboard_actions_helper(
                loading_user_accounts_list,
                success_user_accounts_list,
                fail_user_accounts_list,
                RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_ERR_MSG,
                RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_SUCCESS_MSG,
                app_consts.GET,
                RETRIEVE_ACTIVE_USER_ACCOUNT_LIST,
                null,
                RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG
            )
        );
    };
    // Next Step
    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    // 
    const refreshList = (type) => {
        if (type === app_consts.REFRESH_USER_ACCOUNTS)
        {
            fetchActiveTruckCustCompAccs();
        }
    };

    // Listen to State of user_account_list
    const user_accounts_list = useSelector((state) => state.user_accounts_list.user_accounts_list);

    useEffect(() => {
        if (user_accounts_list.status !== app_consts.IDLE)
        {
            if (user_accounts_list.status === app_consts.LOADING)
            {
                setUserListLoading(true);
            } else
            {
                setUserListLoading(false);
                if (user_accounts_list.status === app_consts.FAIL)
                {
                    messageToast(user_accounts_list.message, app_consts.FAIL);
                } else if (user_accounts_list.status === app_consts.SUCCESS)
                {
                    setUserListData(user_accounts_list.data);
                }
                dispatch(reset_user_accounts_list());
            }
        }
    }, [user_accounts_list]);

    // Add the Data to A Map for RSelect
    useEffect(() => {
        if (user_list_data.truck_accounts)
        {
            setTruckAccounts(user_list_data.truck_accounts);
        }
        if (user_list_data.customer_accounts)
        {
            setCustomerAccounts(user_list_data.customer_accounts);
        }
        if (user_list_data.company_accounts)
        {
            setCompanyAccounts(user_list_data.company_accounts);
        }
    }, [user_list_data]);

    return (
        <>
            {
                loading ?
                    <>
                        <div className='w-full p-5 d-flex justify-center items-center'>
                            <Spinner />
                        </div>
                    </>
                    :
                    error && error.length > 0 ?
                        <>
                            <div className='text-soft alert-icon w-full p-5 d-flex justify-center items-center fw-bold fs-18px'>
                                {error}
                            </div>
                        </>
                        :
                        <>
                            <Card className="card-bordered">
                                <Row className="g-0 col-sep col-sep-md col-sep-xl">
                                    <StepGuideCard sentStep={step} wizard_type={wizardType} wizard_title="Create Physical Delivery Note" />
                                    <Col className="col-md-8 col-xl-8">
                                        {step === 1 ? (
                                            <SelectLoadingOrdersStep
                                                togglestep={step}
                                                refreshList={refreshList}
                                                list_data={user_list_data}
                                                loadingList={userListLoading}
                                                company_accounts_list={company_accounts}
                                                customer_accounts_list={customer_accounts}
                                                weighBridgeFormData={weighBridgeFormData}
                                                setWeighBridgeFormData={setWeighBridgeFormData}
                                                nextStep={nextStep}
                                                setWizardData={setWizardData}
                                            />
                                        ) : step === 2 ? (
                                            <AddProductsStep
                                                weighBridgeFormData={weighBridgeFormData}
                                                setWeighBridgeFormData={setWeighBridgeFormData}
                                                truck_accounts_list={truck_accounts}
                                                loadingList={userListLoading}
                                                sentStep={step}
                                                prevStep={prevStep}
                                                nextStep={nextStep}
                                                refreshList={refreshList}
                                                setWizardData={setWizardData}
                                                wizardData={wizardData}
                                                setContinueProcessingData={setContinueProcessingData}
                                            />
                                        ) : step === 3 ? (
                                            <FinalDetailsStep
                                                weighBridgeFormData={weighBridgeFormData}
                                                setWeighBridgeFormData={setWeighBridgeFormData}
                                                truck_accounts_list={truck_accounts}
                                                loadingList={userListLoading}
                                                sentStep={step}
                                                prevStep={prevStep}
                                                nextStep={nextStep}
                                                refreshList={refreshList}
                                                continue_processing_data={continue_processing_data}
                                                setContinueProcessingData={setContinueProcessingData}
                                            />
                                        ) : step === 4 ? (
                                            <DeliveryNotePDFStep
                                                weighBridgeFormData={weighBridgeFormData}
                                                setWeighBridgeFormData={setWeighBridgeFormData}
                                                truck_accounts_list={truck_accounts}
                                                loadingList={userListLoading}
                                                sentStep={step}
                                                prevStep={prevStep}
                                                nextStep={nextStep}
                                                refreshList={refreshList}
                                                continue_processing_data={continue_processing_data}
                                                setContinueProcessingData={setContinueProcessingData}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </Col>
                                </Row>
                            </Card>
                        </>
            }
        </>
    );
};

export default CreatePhsyicalDeliveryNoteIndex;